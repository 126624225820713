import { gql } from 'graphql-tag';
import StreamTierFullBleedFragment from '@fragments/shared/StreamTierFullBleedFragment';
import StreamTierW100Fragment from '@fragments/shared/StreamTierW100Fragment';
import StreamTierW5050Fragment from '@fragments/shared/StreamTierW5050Fragment';
import StreamTierW25252525Fragment from '@fragments/shared/StreamTierW25252525Fragment';
import StreamTierW252550Fragment from '@fragments/shared/StreamTierW252550Fragment';
import StreamTierW333333Fragment from '@fragments/shared/StreamTierW333333Fragment';
import StreamTierW502525Fragment from '@fragments/shared/StreamTierW502525Fragment';

export default gql`
    fragment StreamTieredFragment on SchemaHelper_StreamTiered {
        ...StreamTierFullBleedFragment
        ...StreamTierW100Fragment
        ...StreamTierW5050Fragment
        ...StreamTierW502525Fragment
        ...StreamTierW252550Fragment
        ...StreamTierW333333Fragment
        ...StreamTierW25252525Fragment
    }
    ${StreamTierFullBleedFragment}
    ${StreamTierW100Fragment}
    ${StreamTierW5050Fragment}
    ${StreamTierW502525Fragment}
    ${StreamTierW252550Fragment}
    ${StreamTierW333333Fragment}
    ${StreamTierW25252525Fragment}
`;
